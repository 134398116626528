import React from 'react'

const Header = () => {
  return (
    <div className='fixed hidden top-0 w-full z-20'>
        <div className="grid grid-cols-12 gap-3 px-[3vw] py-3">
          <div className="col-span-2">
            <h1 className='tracking-widest font-bold text-2xl'></h1>
          </div>
          <div className="col-span-10">
            <ul className='text-2xl flex flex-wrap gap-5 justify-end uppercase'>
              <li className='flex leading-base md:leading-snug'>
                <a  class="group relative block h-fit overflow-hidden font-medium tracking-widest" href="#">
                  <span class="link1">Services</span>
                  <span aria-hidden="true" class="link2">Services</span>
                </a>
              </li>
              <li className='flex leading-base md:leading-snug'>
                <a  class="group relative block h-fit overflow-hidden font-medium tracking-widest" href="#">
                  <span class="link1">Works</span>
                  <span aria-hidden="true" class="link2">Works</span>
                </a>
              </li>
              <li className='flex leading-base md:leading-snug'>
                <a  class="group relative block h-fit overflow-hidden font-medium tracking-widest" href="#">
                  <span class="link1">About</span>
                  <span aria-hidden="true" class="link2">About</span>
                </a>
              </li>
              <li className='flex leading-base md:leading-snug'>
                <a  class="group relative block h-fit overflow-hidden font-medium tracking-widest" href="#">
                  <span class="link1">Testionimal</span>
                  <span aria-hidden="true" class="link2">Testionimal</span>
                </a>
              </li>
              <li className='flex leading-base md:leading-snug'>
                <a  class="group relative block h-fit overflow-hidden font-medium tracking-widest" href="#">
                  <span class="link1">Contact</span>
                  <span aria-hidden="true" class="link2">Contact</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
    </div>
  )
}

export default Header