"use client";
import { gsap } from "gsap";
import { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function Parallax({ className, children, triggerRef, speed = 1, from, to, id = "parallax" }) {

  const trigger = useRef(); // this is the element that will trigger the animation
  const target = useRef();  // this is the element that will be animated

  useEffect(() => {
    // Kiểm tra kích thước màn hình, ví dụ: mobile nhỏ hơn 768px
    const isLargeScreen = window.innerWidth > 768;
  
    if (isLargeScreen) {
      gsap.fromTo(
        target.current,
        { ...from },
        {
          ...to,
          scrollTrigger: {
            id: id,
            trigger: triggerRef?.current || trigger.current, // this is the element that will trigger the animation
            // markers: true,
            start: "top top", // Thay đổi start để kiểm tra
            end: "bottom top", // Thay đổi end để kiểm tra
            scrub: 0.1, // Hoặc giá trị khác
          },
        }
      );
    }
  
  }, []);

  return (
    <div ref={trigger} className={className}>
      <div ref={target}>{children}</div>
    </div>
  );
}