import React, { useEffect, useRef } from 'react'
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AnimatedCursor from 'react-animated-cursor';
import { ReactLenis } from '@studio-freight/react-lenis'
import "./index.css";
import { Parallax } from './components/Parallax';
import Header from './components/Header';
import Aos from 'aos';
import confetti from 'canvas-confetti';
import 'aos/dist/aos.css';


function App() {

  const lenisRef = useRef();
  const boxCenterRefWrapper = useRef();
  const lineWrapperRef = useRef();
  const homeScreenRef = useRef();
  const boxRef1 = useRef([]);
  const boxRef2 = useRef([]);
  const boxCenterRef = useRef([]);
  const textWraperRef = useRef();
  const triggerRef = useRef();
  const triggerRef2 = useRef();
  
  const textRef = useRef([]);
  const lineRef = useRef([]);
  const heroRef = useRef();
  const svgRef = useRef(null);
  const galleryRef = useRef(null);

  const gallery = [
    require("./assets/images/Nhat-Bi-31.jpg"),
    require("./assets/images/Nhat-Bi-29.jpg"),
    require("./assets/images/Nhat-Bi-33.jpg"),
    require("./assets/images/Nhat-Bi-54.jpg"),
    require("./assets/images/Nhat-Bi-35.jpg"),
    require("./assets/images/Nhat-Bi-36.jpg"),
    require("./assets/images/Nhat-Bi-55.jpg"),
    require("./assets/images/Nhat-Bi-38.jpg"),
    require("./assets/images/Nhat-Bi-39.jpg"),
    require("./assets/images/Nhat-Bi-10.jpg"),
    require("./assets/images/Nhat-Bi-11.jpg"),
    // require("./assets/images/Nhat-Bi-13.jpg"),
    // require("./assets/images/Nhat-Bi-44.jpg"),
    // require("./assets/images/Nhat-Bi-15.jpg"),
    // require("./assets/images/Nhat-Bi-16.jpg"),
    // require("./assets/images/Nhat-Bi-17.jpg"),
    // require("./assets/images/Nhat-Bi-43.jpg"),
    // require("./assets/images/Nhat-Bi-42.jpg"),
    // require("./assets/images/Nhat-Bi-41.jpg"),
  ];

  useEffect(() => {
    Aos.init();

      function update(time) {
        lenisRef.current?.lenis?.raf(time * 300);
              // Thiết lập ScrollTrigger để sử dụng với Lenis
              ScrollTrigger.scrollerProxy(document.body, {
                scrollTop(value) {
                  if (arguments.length) {
                    lenisRef.current.scrollTo(value, 0, 0); // Cuộn tới giá trị mới
                  }
                  return lenisRef.current.scroll.instance.scroll.y; // Trả về vị trí cuộn hiện tại
                },
                getBoundingClientRect() {
                  return {
                    top: 0,
                    left: 0,
                    width: window.innerWidth,
                    height: window.innerHeight,
                  };
                },
              });
      }

       gsap.ticker.add(update);
    
    return () => {
      gsap.ticker.remove(update);
    }
  }, []);

  useEffect(() => {
    const gallery = galleryRef.current;
    const totalWidth = gallery.scrollWidth; // Chiều rộng tổng của gallery
    const windowWidth = window.innerWidth; // Chiều rộng của cửa sổ

    // Thiết lập GSAP cho scroll
    gsap.to(gallery, {
      x: () => -(totalWidth - windowWidth) + "px", // Cuộn ngang
      ease: "none",
      scrollTrigger: {
        trigger: ".gallery-wrapper",
        pin: true, // Giữ gallery cố định khi cuộn
        scrub: 1, // Kéo theo cuộn
        end: () => "+=" + totalWidth, // Kết thúc khi cuộn hết chiều rộng gallery
      },
    });

    // Clean up function to prevent memory leaks
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  useEffect(() => {
    gsap.to(
      lineRef.current,
      {
        width: "100%",
        scale: .65,
        scrollTrigger: {
          trigger: lineWrapperRef.current, 
          start: "top bottom", // Thay đổi start để kiểm tra
          end: "top bottom", // Thay đổi end để kiểm tra
          scrub: 0.1, // Hoặc giá trị khác
        },
      }
    );
    gsap.fromTo(
      boxRef1.current,
      { 
        opacity: 1,
        y: 0
      },
      {
        y: 480,
        scale: .65,
        scrollTrigger: {
          trigger: heroRef.current, 
          start: "top bottom", // Thay đổi start để kiểm tra
          end: "top bottom", // Thay đổi end để kiểm tra
          scrub: 0.1, // Hoặc giá trị khác
        },
      }
    );
    gsap.fromTo(
      boxRef2.current,
      { 
        opacity: 1,
        y: 0
      },
      {
        y: 380,
        scrollTrigger: {
          trigger: heroRef.current, 
          start: "top bottom", // Thay đổi start để kiểm tra
          end: "bottom bottom", // Thay đổi end để kiểm tra
          scrub: 0.1, // Hoặc giá trị khác
        },
      }
    );
    gsap.fromTo(
      boxCenterRef.current,
      { 
        opacity: 1,
        scale: .95,
        y: 0
      },
      {
        y: -220,
        scale: 1.15,
        scrollTrigger: {
          trigger: boxCenterRefWrapper.current, 
          start: "top bottom", // Thay đổi start để kiểm tra
          end: "bottom bottom", // Thay đổi end để kiểm tra
          scrub: 0.1, // Hoặc giá trị khác
        },
      }
    );
    gsap.fromTo(
      textRef.current,
      { 
        opacity: 1,
        y: 0
      },
      {
        y: 380,
        scale: .55,
        scrollTrigger: {
          trigger: textWraperRef.current, 
          start: "top bottom", // Thay đổi start để kiểm tra
          end: "bottom top", // Thay đổi end để kiểm tra
          scrub: 0.1, // Hoặc giá trị khác
        },
      }
    );
  }, [])

    // Danh sách emoji liên quan đến đám cưới
  const weddingEmojis = ['💍', '👰', '🤵', '💒', '🎉', '💖'];

  const randomEmoji = () => {
    return weddingEmojis[Math.floor(Math.random() * weddingEmojis.length)];
  };

  const handleClick = (e) => {
    const { clientX: x, clientY: y } = e;

    for (let i = 0; i < 10; i++) {
      // Tạo pháo hoa với mỗi emoji ngẫu nhiên
      confetti({
        particleCount: 10,
        spread: 500,
        startVelocity: 80,
        ticks: 3200,
        gravity: 0.5,
        origin: {
          x: x / window.innerWidth,
          y: y / window.innerHeight
        },
        scalar: 1,
        shapes: ['text'], // Định dạng hạt thành dạng text (emoji)
        text: randomEmoji() // Lấy emoji ngẫu nhiên từ danh sách
      });
    }
  };

  const isLargeScreen = window.innerWidth > 768;

  return (
    <ReactLenis root ref={lenisRef} className='text-peridot-1 text-[18px]' autoRaf={false}>
      <div onClick={handleClick}>
        <Header />
        <section ref={homeScreenRef}  className="relative overflow-hidden py-[3vh] pb-[10vh] px-[3vw] h-screen flex items-center lg:h-auto">
            <div className='w-full'>
            <Parallax triggerRef={homeScreenRef} from={{ scale: 1, y: 0 }} to={{ y: -240, opacity: .15}} className='absolute w-full z-10 left-0 z-20 top-[calc(50%-90px)]  flex justify-center'>
              <svg className='w-[75vw] lg:w-[40vw]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 919.25 419.78">
                  <title>Asset 5</title>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <g id="Layer_1-2-2" data-name="Layer 1-2">
                        <path d="M258.73,0V122.45h-3.85L170,10V122.45h-3.85V0h15.39l73.3,97.09V0Z" />
                        <path d="M431.51,0V122.45h-15.4V66.13h-65v56.32H335.7V0h15.39V62.28h65.08V0Z" />
                        <path d="M579.38,83.27h-60l-16.79,39.18h-5.08L550.34,0h10l52.83,122.45h-17Zm-1.57-3.67L549.47,13.47,521,79.6Z" />
                        <path d="M753.09,3.85h-45v118.6h-15.4V3.85H647.61V0H753.09Z" />
                        <path d="M92.54,186.6V309.05H88.69L3.85,196.57V309.05H0V186.6H15.39l73.3,97.09V186.6Z" />
                        <path d="M269.75,245.9v47.23A62,62,0,0,1,226,311.33c-35,0-62.28-27.12-62.28-63.68S191,183.51,226,183.51A61.76,61.76,0,0,1,264.5,196.8l-5.6,6.94A40.79,40.79,0,0,0,226,187.65c-24.67,0-44.79,25-44.79,60s20.12,59.83,44.79,59.83a37.91,37.91,0,0,0,28.16-13.65V249.92H226v-4Z" />
                        <path d="M438.56,186.6h3.85v81.63c0,29.16-22.74,43-50.73,43S341,297.39,341,268.23l-.18-81.63h15.4v81.63c0,27.47,17.67,38.49,35.51,38.49,27.46,0,46.88-13.12,46.88-38.66Z" />
                        <path d="M608.25,186.6l-41.11,70.67v51.78h-15.4V259.49L505.56,186.6H524.1l41.29,65.77L603.7,186.6Z" />
                        <path d="M688.37,190.45v58.31h57.55v3.85H688.37v52.48h71v4H673V186.6h86.42v3.85Z" />
                        <path d="M919.25,190.45H874.32v118.6H858.93V190.45H813.8V186.6H919.25Z" />
                      </g>
                      <path d="M412.61,300.67c-16.93,0-22.53-7.83-22.53-8.67s1-1.27,1.54-1.27c1.26,0,6.85,6.72,25.47,6.72,23.37,0,59.76-17.77,78.65-32.75a6,6,0,0,1,3.23-1.54c.56,0,.83.7.83,1.26a3.32,3.32,0,0,1-.83,2c-10.92,11.34-56.13,71.1-56.13,87.9,0,2.8,1.26,4.34,4.06,4.34,16,0,75.86-67.75,75.86-67.75,9.94-11.75,20.29-25.19,22.25-27.29.7-.7,2.52-2.24,3.36-2.24s.84.56.84,1.12a4.35,4.35,0,0,1-.84,2.38c-1.12,2-4.2,5-11.48,14.56-3.91,5-41,55.14-41,74.18,0,4.48,2.1,7.27,7.14,7.27,33,0,76-105.81,133.1-121.2,0,0,1.68,0,1.68,2.1,0,1.82-1,2.66-1.82,2.66-65.37,21.83-95.74,118.68-133.8,118.68-6.45,0-9-3.63-9-9.23,0-16.8,22.25-50.53,24.21-53.05.42-.56.56-.84.56-1.12s0-.28-.14-.28-.42.14-.84.56c-3.5,3.5-53.88,62-72.64,62-3.92,0-5.46-2.11-5.46-5.6,0-19.18,49-81.6,50.67-84.26,0-.14,0-.28-.14-.28a1.06,1.06,0,0,0-.56.14S446.62,300.67,412.61,300.67Z" />
                      <path d="M579.71,345.32l1.4,1.54c-3.64,4.2-17.77,13.71-26.31,13.71-3.08,0-6.58-2.23-6.58-6.15,0-8.12,11.48-15.82,16.8-15.82,2,0,2.65,1.12,2.65,2.38,0,3.36-8.25,9-15.81,12.88,0,2.38,1.12,4.06,4.62,4.06C562.78,357.92,575.09,349.94,579.71,345.32Zm-27.43,6.3c7.14-3.5,12-7.42,12-9.66,0-.56-.41-.84-1.11-.84C558.86,341.12,553.68,346.86,552.28,351.62Z" />
                      <path d="M628,345.32l1.4,1.54c-3.64,4.2-17.21,12.17-25.75,12.17-5.88,0-7.7-2.65-7.7-6a23.77,23.77,0,0,1,1.68-7.28c.28-.84-14.83,14.13-21.69,14.13-2,0-2.94-1.25-2.94-3.21,0-5.46,7.42-16,22.25-19a1.37,1.37,0,0,1,1.54,1.54c0,.7-.56,1.26-2.1,1.54-9,1.26-18.89,11.06-18.89,14.84,0,1,.56,1.54,2,1.54,3.64,0,14.83-7,27.85-25.48,7.28-10.35,18.33-27.71,33-50.38,3.08-4.62,4.9-6.3,5.88-6.3.56,0,1,.7,1,1.26,0,1-.7,2.8-2.94,6.3-7.7,11.89-23.8,35-35,51.78-3.08,4.76-8.4,12.18-8.4,16.94,0,2.94,1.82,4.9,7,4.9C613,356.1,623.38,349.94,628,345.32Z" />
                      <path d="M652.9,345.46l1.4,1.54c-3.64,4.2-17.22,12.18-25.75,12.18-3.08,0-4.2-1.68-4.2-4.06,0-6,7.28-16.38,9.24-16.38a1,1,0,0,1,1,.56,2.6,2.6,0,0,1,.28,1c0,1.4-6.71,9.52-6.71,13.58,0,1.4.84,2.38,2.94,2.38C637.92,356.24,648.28,350.08,652.9,345.46Zm-4.34-27.29c.7,0,1,.56,1,1.26,0,1.4-1.82,3.92-3.36,3.92a1.38,1.38,0,0,1-1.26-1.4C644.92,320.55,646.88,318.17,648.56,318.17Z" />
                      <path d="M679.35,343.64c.7,0,1.12,1.4,1.12,2,0,1.4-3.64,6.16-3.64,9.1,0,2.1,1.4,2.8,3.64,2.8,6.86,0,20-7.56,24.63-12.18l1.4,1.54c-3.64,4.2-18.61,12.6-27.71,12.6-3.36,0-5.74-1.26-5.74-4.48a13.83,13.83,0,0,1,1.54-5.18c.28-.7-16.52,9.66-20.43,9.66-1.54,0-2.1-.84-2.1-2,0-4.2,7.56-13.86,7.28-14.42-1,0-4.2,3.36-6.16,4.9a2,2,0,0,1-1.4.56A3.69,3.69,0,0,1,650,348c1.12-.84,2-1.68,3.08-2.66,1.54-1.54,6.16-5.6,8.67-5.6,1,0,1.68.56,1.68,2.38s-6.85,10.64-6.85,13.44c0,.28.14.56.42.56C659.89,356.1,678.09,343.64,679.35,343.64Z" />
                      <path d="M707.62,369.25c-12.6,21.42-30.1,50.53-42.27,50.53-3.64,0-5-1.68-5-4.06,0-11.76,17.78-29.11,45.63-47.87,4.89-8.25,8.11-14.27,8.11-14.27a.41.41,0,0,0-.42-.42c-2.37,1.68-9,6-12.73,6-1.82,0-2.94-1-2.94-3.5,0-7.7,18.33-19.32,22-19.32.84,0,2.38,1,2.38,2,0,.28-.84.56-1.82.84-8.68,2.24-19,11.9-19,14.7,0,1.12.84,2,2.52,2,4.33,0,13.15-8.26,15.95-11.9,1.26-1.68,2.1-2.24,2.8-2.24A1.23,1.23,0,0,1,724,342.8a5,5,0,0,1-.28,1.4c-.42.84-3.08,4.76-3.92,5.88-2.38,3.22-5.74,8.54-9.66,14.83,7-4.47,14.56-9.09,22.54-13.71a42.24,42.24,0,0,0,7.84-5.88l1.4,1.54a34.39,34.39,0,0,1-6.72,5.18C725.39,357.78,716.15,363.51,707.62,369.25Zm-4.48,3.22c-23.24,16.38-39.33,32.19-39.33,42.27,0,1.68,1.12,2.52,3.5,2.52C675,417.26,691.94,391.23,703.14,372.47Z" />
                      <path d="M816.78,345.32l1.4,1.54C814.54,351.06,801,359,792.42,359c-5.87,0-7.69-2.65-7.69-6a23.77,23.77,0,0,1,1.68-7.28c.27-.84-14.84,14.13-21.7,14.13-2,0-2.94-1.25-2.94-3.21,0-5.46,7.42-16,22.26-19a1.37,1.37,0,0,1,1.54,1.54c0,.7-.57,1.26-2.11,1.54-9,1.26-18.89,11.06-18.89,14.84,0,1,.56,1.54,2,1.54,3.64,0,14.84-7,27.85-25.48,7.28-10.35,18.34-27.71,33-50.38,3.08-4.62,4.9-6.3,5.88-6.3.56,0,1,.7,1,1.26,0,1-.7,2.8-2.94,6.3-7.7,11.89-23.79,35-35,51.78-3.08,4.76-8.4,12.18-8.4,16.94,0,2.94,1.82,4.9,7,4.9C801.8,356.1,812.16,349.94,816.78,345.32Z" />
                      <path d="M831.05,348.4c.84-3.22,3.08-6,6-6,.7,0,1,.14,1,.56,0,1-2.11,3.36-5,5.88.14,5.32,2.93,7.28,6.86,7.28,6.85,0,17.07-6,21.83-10.78l1.4,1.54c-3.64,4.2-17.22,12.17-25.75,12.17-4.76,0-6.58-3.49-6.58-7.41v-.84c-5.74,4.48-13.3,9-16.66,9-2.8,0-3.92-1.39-3.92-3.49,0-6.44,11.34-19.6,27.3-19.6,4,0,4.19,1,4.19,1.68,0,1.54-1.54,1.54-2,1.54a25.72,25.72,0,0,0-3.21-.14c-13.86,0-22.68,10.64-22.68,15a1.63,1.63,0,0,0,1.82,1.82C819.57,356.52,828.81,350.08,831.05,348.4Z" />
                      <path d="M871.07,365.05c7.7-4.61,15.68-9.09,23.8-13.85a47.85,47.85,0,0,0,7.83-5.88l1.4,1.54a34.27,34.27,0,0,1-6.71,5.18c-9.8,5.74-20,11.47-29.68,17.35-16.79,21.56-42.27,50.39-52.34,50.39-3.64,0-5-1.68-5-4.06,0-11.48,23.93-28.27,53.89-46.61,7-8.95,12-15.53,12-15.53s3.22-4.9,3.08-4.9c0-.28-.14-.42-.28-.42s-14,9.52-19.74,9.52c-2.09,0-3.07-1.12-3.07-2.8,0-5.18,8.53-15.82,11.47-15.82,1,0,2,.56,2,1s-.42.56-.7.84c-2.38,2.24-9.09,8.68-9.09,12.88,0,.7.55,1,1.53,1,7.56,0,22.82-13.58,25.34-16.38,1.54-1.68,2.66-2.52,3.5-2.52s1.4.84,1.4,1.68c0,2.1-8.68,10.92-9.66,12.46C879.89,353.44,876,358.62,871.07,365.05Zm-53.74,52.21c7.41,0,28.55-24.77,43.39-43.53-25.76,16-46.89,31.21-46.89,41C813.83,416.42,815,417.26,817.33,417.26Z" />
                    </g>
                  </g>
                </svg>            
            </Parallax>
            <div className="grid grid-cols-12 items-center gap-[35px]">
              <div className='col-span-4 flex items-center'>
                <Parallax className="relative" from={{ scale: 1, yt: 0 }} to={{y: -100}}>
                  <img className='overflow-hidden w-[160px] lg:w-full h-[200px] lg:h-[65vh] object-cover object-center block' src={require("./assets/images/Nhat-Bi-18.jpg")} alt="" />
                  <div className="w-full h-full absolute z-1 top-0 bottom-0 left-0 right-0 bg-white overlay-bottom" data-aos data-aos-anchor-placement="top-center"></div>
                </Parallax>
              </div>
              <div className='flex col-span-4 items-end justify-center'>
  
              </div>
              <div className='flex col-span-4 items-end justify-end'>
                <Parallax className="relative" from={{ scale: 1 }} to={{y: 230}}>
                  <img className='overflow-hidden w-[160px] h-[200px] lg:w-[25vw] lg:h-[65vh] object-cover object-center block' src={require("./assets/images/Nhat-Bi-35.jpg")} alt="" />
                  <div className="w-full h-full absolute z-1 top-0 bottom-0 left-0 right-0 bg-white overlay-bottom" data-aos data-aos-anchor-placement="top-center"></div>
                </Parallax>
              </div>
            </div>
            <div className="grid grid-cols-3 items-center mt-[200px]">
                <div></div>
                <Parallax className="relative" from={{ scale: 1 }} to={{ y: 230}}>
                  <img className='w-[180px] h-[220px] lg:w-[20vw] lg:h-[45vh] mx-auto object-left object-cover' src={require("./assets/images/Nhat-Bi-51.jpg")} alt="" />
                  <div className="w-full h-full absolute z-1 top-0 bottom-0 left-0 right-0 bg-white overlay-bottom" data-aos data-aos-anchor-placement="top"></div>
                </Parallax>
                <div></div>
              </div>
            </div>
        </section>

        <section
          ref={lineWrapperRef} 
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          className="mx-auto w-full py-[10vh] px-[3vw] relative">
          <h3 className="text-center text-peridot text-[55px]">Timeline</h3>
          <div className="max-w-[1600px] mx-auto grid grid-cols-1 lg:grid-cols-2 gap-[55px]">
            <div>
              <h1 className='uppercase font-bold text-[32px] lg:text-[55px] text-blacker'>Nhà gái</h1>
              <ul className='line-list'>
                <li className='py-5 block'>
                  <p className='text-[18px] lg:text-[28px] text-blacker'>Ông : Phêrô Lê Vĩnh Nguyên</p>
                </li>
                <li className='py-5 block'>
                  <p className='text-[18px] lg:text-[28px] text-blacker'>Bà : Maria Nguyễn Thị Hoa</p>
                </li>
                <li className='py-5 block'>
                  <p className='text-[18px] lg:text-[28px] text-blacker'>Đ/c : E41,Tổ 9, Phước Thiện, Phước Tỉnh, H.Long Điền, BRVT</p>
                </li>
                <li className='py-5 block'>
                  <p className='text-[18px] lg:text-[28px] text-blacker'>Tiệc cưới vào lúc 11giờ 00 Thứ Bảy ngày 12/10/24</p>
                </li>
                <li className='py-5 block'>
                  <p className='text-[18px] lg:text-[28px] text-blacker'>Tại: Nhà Hàng Kim Sơn 2</p>
                </li>
                <li className='py-5 block'>
                  <p className='text-[18px] lg:text-[28px] text-blacker'>Địa chỉ: A24, Hương lộ 5, Xã Phước Tỉnh, H.Long Điền, BRVT <a className='underline' href="https://maps.app.goo.gl/6VT9udjLrwsTMkzX7?g_st=com.google.maps.preview.copy" target="_blank">(Xem đường đi)</a></p>
                </li>
              </ul>
            </div>
            <div>
              <h1 className='uppercase font-bold text-[32px] lg:text-[55px] text-blacker'>Nhà trai</h1>
              <ul className='line-list'>
                <li className='py-5 block'><p className='text-[18px] lg:text-[28px] text-blacker'>Ông : Giuse Nguyễn Văn Ngân</p></li>
                <li className='py-5 block'><p className='text-[18px] lg:text-[28px] text-blacker'>Bà : Maria Phạm Thị Nhiệm</p></li>
                <li className='py-5 block'><p className='text-[18px] lg:text-[28px] text-blacker'>Đ/c : E26, Tổ 7, Phước Thiện, Phước Tỉnh, H.Long Điền, BRVT</p></li>
                <li className='py-5 block'><p className='text-[18px] lg:text-[28px] text-blacker'>Tiệc cưới vào lúc 11giờ 00 Chủ Nhật ngày 13/10/24</p></li>
                <li className='py-5 block'>
                  <p className='text-[18px] lg:text-[28px] text-blacker'>Tại: Khu Du Lịch Thuyền & Biển</p>
                </li>
                <li className='py-5 block'>
                  <p className='text-[18px] lg:text-[28px] text-blacker'>Địa chỉ: 41 Tổ 26 Phước Lâm, Xã Phước Hưng, H.Long Điền, BRVT <a className='underline' href="https://maps.app.goo.gl/yxbgZgtRYfYtTQLn8?g_st=com.google.maps.preview.copy" target="_blank">(Xem đường đi)</a></p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="lg:py-[30vh] px-[3vw] relative" ref={triggerRef}>
          <div className="grid grid-cols-3 relative">
            <Parallax className="absolute" triggerRef={triggerRef} from={{ scale: 1 }} to={{ y: 230}}>
              <div className="w-full h-full absolute z-1 top-0 bottom-0 left-0 bg-white overlay-left" data-aos data-aos-anchor-placement="top-center"></div>
              <img className='w-[160px] h-[200px] lg:w-[45vw] opacity-50 max-w-[45vw] lg:h-[55vh] mx-auto object-left object-cover' src={require("./assets/images/Nhat-Bi-14.jpg")} alt="" />
            </Parallax>
            <div className='col-span-3 lg:col-span-3 relative z-10'>
              <div className="max-w-[640px] mx-auto">
                <h3 className='mb-[35px] lg:mb-[75px] text-[35px] lg:text-[55px] text-center text-peridot' data-aos="fade-up">Trust in Jesus</h3>
                <Parallax triggerRef={triggerRef} from={{ scale: 1 }} to={{ y: 280}}>
                    <div className="relative mb-[55px]" data-aos="fade-up">
                      <h3 className='text-[75px] lg:text-[120px] text-blacker text-center'>Our story</h3>
                      <h3 className='text-[55px] lg:text-[55px] text-right text-peridot absolute -bottom-[35px] right-[75px]'>outlined</h3>
                    </div>
                    <div data-aos="fade-up">
                      <p className='mb-3' >Hai trái tim, một hành trình. Từ lần đầu gặp gỡ đầy duyên phận đến lời cầu hôn xúc động, câu chuyện tình yêu của chúng tôi là một cuộc hành trình đầy hạnh phúc. Chúng tôi rất mong muốn được chia sẻ ngày trọng đại này với bạn bè và gia đình</p>
                      <p>– những người đã luôn đồng hành và ủng hộ chúng tôi.</p>
                    </div>
                  </Parallax>
              </div>
            </div>
            <div className='hidden lg:block top-[calc(80%-100px)] right-0 absolute lg:absolute bottom-0'>
                <Parallax className="relative" from={{ scale: 1 }} to={{ y: 230}}>
                  <div className="w-full h-full absolute z-1 top-0 bottom-0 left-0 right-0 bg-white overlay-bottom" data-aos data-aos-anchor-placement="top-center"></div>
                  <img className='w-[160px] h-[200px]  lg:w-[20vw] lg:h-[55vh] mx-auto object-left object-cover' src={require("./assets/images/Nhat-Bi-40.jpg")} alt="" />
                </Parallax>
              </div>
          </div>
        </section>
        <section ref={triggerRef2} className="py-[15vh] px-[3vw] relative">
          <div className="grid grid-cols-12 s-screen gap-[35px] mb-[55px] items-center lg:py-[10vh]">
              <div className="col-span-12 lg:col-span-6 relative">
                  <div className="grid grid-cols-12 gap-[15px] lg:gap-[35px]">
                    <Parallax className="relative col-span-7" triggerRef={triggerRef2} from={{ scale: 1 }} to={{ y: -120}}>
                        <div className="w-full h-full absolute z-1 top-0 bottom-0 left-0 right-0 bg-white overlay-bottom" data-aos data-aos-anchor-placement="top-center"></div>
                        <img className='w-[full] h-[380px] lg:h-[75vh] mx-auto object-center object-cover' src={require("./assets/images/Nhat-Bi-54.jpg")} alt="" />
                    </Parallax>
                    <Parallax className="relative col-span-5 mt-10" triggerRef={triggerRef2} from={{ scale: 1 }} to={{ y: 220}}>
                        <div className="w-full h-full absolute z-1 top-0 bottom-0 left-0 right-0 bg-white overlay-bottom" data-aos data-aos-anchor-placement="top-center"></div>
                        <img className='w-[260px] h-[220px] lg:h-[35vh] lg:w-full object-center object-cover' src={require("./assets/images/Nhat-Bi-32.jpg")} alt="" />
                    </Parallax>
                  </div>
              </div>
              <div className='col-span-12 lg:col-span-6'>
                <div className="max-w-[540px]">
                  <Parallax className="relative" triggerRef={triggerRef2} from={{ scale: 1 }} to={{ y: 120}}>
                    <p className='text-[38px] lg:text-[45px] mb-10 text-blacker' data-aos="fade-up">Từ Những Ngày Đầu Đến Ngày Trọng Đại</p>
                    <p data-aos="fade-up">Mọi câu chuyện tình yêu đều bắt đầu từ một khoảnh khắc đáng nhớ. Đối với chúng tôi, đó là khi ánh mắt của chúng tôi gặp nhau lần đầu tiên. Từ những cuộc trò chuyện đầy chân thành đến những khoảnh khắc hạnh phúc cùng nhau, chúng tôi đã cùng nhau xây dựng một tình yêu chân thành và vững chắc.</p>
                  </Parallax>
                </div>
              </div>
          </div>
          <div className="gallery-wrapper grid grid-cols-12 items-center gap-[35px] lg:py-[10vh]">
              <div className='col-span-12 lg:col-span-4'>
                <p className='text-[38px] lg:text-[45px] mb-10 text-blacker' data-aos="fade-up">Hành Trình Tình Yêu Qua Những Hình Ảnh</p>
                <p data-aos="fade-up">Chúng tôi tin rằng mỗi bức ảnh đều lưu lại một câu chuyện đặc biệt. Dưới đây là một vài khoảnh khắc mà chúng tôi yêu thích nhất trong hành trình yêu thương của mình. Hãy cùng ngắm nhìn những hình ảnh ngọt ngào này!</p>
              </div>
              <div className="grid col-span-12 lg:col-span-8 overflow-hidden relative flex items-center">
                <div className="gallery" ref={galleryRef}>
                    {
                        gallery.map((val, index) => {
                          return (
                            <div key={index} className="gallery-item" data-aos="fade-up">
                                <div className="relative mr-[35px]">
                                    <img className={ index % 2 == 0 ? 'w-[45vw] lg:w-[28vw]' : 'w-[38vw] lg:w-[17vw]' + ' h-auto mx-auto object-left object-cover'} src={val} alt="" />
                                </div>
                              </div>
                          )
                        })
                      }
                </div>
              </div>
          </div>
        </section>

        <section className="pt-[5vh] pb-[35vh]">
          <div className='max-w-[640px] px-[3vw] mx-auto'>
            <div className="relative mb-10">
              <h1 className='text-[38px] lg:text-[45px] text-blacker text-center' data-aos="fade-up">Lời Cảm Ơn</h1>
            </div>
            <p className='max-w-[640px] mb-10' data-aos="fade-up">
              Cảm ơn tất cả mọi người đã đến chia sẻ niềm vui và kỷ niệm cùng chúng tôi. Những khoảnh khắc này sẽ mãi là kỷ niệm đáng nhớ trong lòng chúng tôi. Cảm ơn vì tình yêu, sự ủng hộ và những lời chúc tốt đẹp. Chúng tôi rất hạnh phúc vì có các bạn bên cạnh trong chặng đường mới này.
            </p>
            <h3 className="text-peridot text-end text-[35px]" data-aos="fade-up">Nhat - Nguyet</h3>
          </div>
        </section>
      </div>
      {
        isLargeScreen ? <AnimatedCursor
        innerSize={8}
        outerSize={35}
        innerScale={1}
        outerScale={2}
        outerAlpha={0}
        hasBlendMode={true}
        innerStyle={{
          backgroundColor: 'var(--cursor-color)'
        }}
        outerStyle={{
          border: '3px solid var(--cursor-color)'
        }}
      /> : ""
      }
    </ReactLenis>
    
  );
}

export default App;
